<template>
    <div>
        <custom-export-category-form></custom-export-category-form>
    </div>
</template>

<script>
import customExportCategoryForm from '@/components/pages/customExportCategory/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        customExportCategoryForm,
    },

    mixins: [dirtyForm],
}
</script>
